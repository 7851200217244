import React from "react";
import {Link} from "react-router-dom";

const Viking = () => {
    return (
        <div>
            <h1>
                Viking Lotto
            </h1>
        </div>
    );
};

export default Viking;