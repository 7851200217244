import React from "react";

const Login = () => {
    return (
        <div>
            <p>
                Hell naw, trudde du at liksom bare sko ha lagt inn et
                intastingsfelt her sånn at du casually bare han injecta meg?!
            </p>
        </div>
    )
}

export default Login;