import React from "react";
import {Link} from "react-router-dom";

const About = () => {
    return (
        <div>
            <h1>
                Bro, do you see how smooth the navbar is moving when scrolling? 🤤
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <h1>
                This is a webpage about me
            </h1>
            <Link to="/">Home</Link>
        </div>
    );
};

export default About;
